var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ui-text-input-wrapper"},[(_vm.label)?_c('label',{attrs:{"for":_vm.label}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('input',{class:[
      {
        [_setup.BASE_CLASSES]: true,
        [_setup.BASE_SMALL_CLASSES]: _setup.isSmall,
        [_setup.BASE_MEDIUM_CLASSES]: _setup.isMedium,
        [_setup.BASE_LARGE_CLASSES]: _setup.isLarge,
      },
      _setup.props.className,
    ],attrs:{"id":_vm.label,"placeholder":_vm.placeholder},domProps:{"value":_setup.inputValue},on:{"input":_setup.updateValue}})])
}
var staticRenderFns = []

export { render, staticRenderFns }