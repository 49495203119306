export const ApplicationActions = {
  shortlist: "shortlist",
  decline: "decline",
  approve: "approve",
} as const

export const ApplicationStates = {
  shortlisted: "shortlisted",
  declined: "declined",
  approved: "approved",
  pending: "pending",
  rejected: "rejected",
} as const
