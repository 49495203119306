export const Actions = {
  Edit: "edit",
  Publish: "publish",
  Unpublish: "unpublish",
  Fill: "fill",
  CopyLink: "copy-link",
  View: "view",
} as const

export type Action = (typeof Actions)[keyof typeof Actions]
