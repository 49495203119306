import { render, staticRenderFns } from "./ActionsTab.vue?vue&type=template&id=a3cb1cc2&scoped=true&"
import script from "./ActionsTab.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ActionsTab.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./ActionsTab.vue?vue&type=style&index=0&id=a3cb1cc2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3cb1cc2",
  null
  
)

export default component.exports