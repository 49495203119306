
import { Vue, Prop, Component, Watch } from "vue-property-decorator"
import JobRoleSelect from "@/components/widgets/input/JobRoleSelect.vue"
import AddExecMilestoneModal from "@/components/missions/AddExecMilestoneModal.vue"
import AddConsultantPaymentForm from "@/components/forms/AddConsultantPaymentForm.vue"
import KeywordField from "../fields/KeywordField.vue"
import {
  MissionLifecycle,
  MissionStaffingPosition,
  CreateMissionExecutionStaffingPositionMutation,
  MissionStaffingMilestone,
  UpdateMissionExecutionStaffingPositionMutation,
} from "@/gql"

@Component({
  components: {
    JobRoleSelect,
    AddExecMilestoneModal,
    AddConsultantPaymentForm,
    KeywordField,
  },
})
export default class AddExecutionStaffPositionForm extends Vue {
  @Prop({ required: true })
  missionLifecycle!: MissionLifecycle

  @Prop({ required: false })
  staffPosition!: MissionStaffingPosition

  @Prop({ required: false })
  refetchQueries!: any

  readonly mutation = CreateMissionExecutionStaffingPositionMutation
  milestones: MissionStaffingMilestone[] = []
  payments: any[] = []
  showAddPaymentModal = false
  activePayment: any = null
  activeMilestone: any = null
  loading = false
  requiredTags: string[] = []
  showDeletePaymentDialog = false
  showDeleteMilestoneDialog = false
  showDeleteErrorDialog = false
  showContinuePrompt = false
  forceDelete = false
  errorMessage = ""

  defaultForm: {
    missionId: number
    name: string
    jobTitleId: number
    description: string
    milestones: MissionStaffingMilestone[] | null
    payments: any[]
    requiredExpertiseTags: string[]
  } = {
    missionId: -1,
    name: "",
    jobTitleId: 0,
    description: "",
    milestones: [],
    payments: [],
    requiredExpertiseTags: [],
  }

  form = { ...this.defaultForm }

  showTooltip = false

  resetForm() {
    this.$set(this, "form", { ...this.defaultForm })
    this.$refs.observer && (this.$refs.observer as any).reset()
  }

  async onAddPosition(force = false) {
    if ((!this.form.milestones?.length || !this.form.payments?.length) && !force) {
      this.showContinuePrompt = true
      return
    }

    this.loading = true

    const result = await this.mutate({
      mutation: this.mutation,
      variables: {
        missionId: this.missionLifecycle.id,
        name: this.form.name,
        jobTitleId: this.form.jobTitleId,
        description: this.form.description,
        requiredExpertiseTags: this.form.requiredExpertiseTags,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.loading = false
      },
    })

    if (result.data && !result.data.createMissionExecutionStaffingPosition.error) {
      this.resetForm()

      this.addSuccess("Staff position added successfully")
      this.showContinuePrompt = false

      this.$emit("close")
      this.$emit("save")

      return result.data?.createMissionExecutionStaffingPosition.missionStaffingPosition
    }
  }

  async onUpdatePosition() {
    this.loading = true

    const result = await this.mutate({
      mutation: UpdateMissionExecutionStaffingPositionMutation,
      variables: {
        id: this.staffPosition.prn,
        name: this.form.name,
        description: this.form.description,
        requiredExpertiseTags: this.form.requiredExpertiseTags,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.loading = false
      },
    })

    if (result.data?.updateMissionExecutionStaffingPosition.missionStaffingPosition) {
      this.resetForm()
      this.addSuccess("Staff position updated successfully")

      this.$emit("close")
      this.$emit("save")

      // return result.data?.updateMissionExecutionStaffingPosition.missionStaffingPosition
    }
  }

  getRequiredTagNames(tags: MissionStaffingPosition["requiredExpertise"]) {
    return tags.map((tag) => tag.name)
  }

  @Watch("staffPosition", {
    immediate: true,
  })
  populateForm() {
    if (this.staffPosition) {
      const staffPosition = JSON.parse(JSON.stringify(this.staffPosition))

      this.form.name = staffPosition.name
      this.form.jobTitleId = staffPosition.jobTitle.id
      this.form.description = staffPosition.description
      this.form.requiredExpertiseTags = this.getRequiredTagNames(staffPosition.requiredExpertise)

      this.requiredTags = this.getRequiredTagNames(staffPosition.requiredExpertise)
    } else {
      this.form = { ...this.defaultForm }
      this.milestones.length = 0
    }
  }

  get deliverables(): { id: number; name: string }[] {
    let deliverables = this.missionLifecycle.missionContract?.deliverables

    return (
      deliverables?.map((deliverable) => {
        return {
          id: deliverable.id,
          name: deliverable.name || deliverable.missionScopeActivity?.name || "unnamed",
        }
      }) || []
    )
  }
}
