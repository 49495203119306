import {
  MissionLifecycleFragmentFragment,
  MissionLifecyclesQuery,
  MissionLifecyclesQueryQueryVariables,
  UpdateMissionLifecycleMutation,
  UpdateMissionLifecycleMutationMutation,
  UpdateMissionLifecycleMutationMutationVariables,
} from "@/gql"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import { inject, provide, ref, Ref } from "vue"
import { UpdateMissionLifecycleInput } from "../types"
import { useMutation } from "@/hooks/useMutation"
import { useNotification } from "@/hooks/useNotification"

type MissionLifecycleContext = {
  mission: MissionLifecycleFragmentFragment
  refetchQueries: RefetchQueryDescription
  updateMissionLifecycle: ({
    updateInput,
    onSuccess,
    onDone,
  }: {
    updateInput: UpdateMissionLifecycleInput
    onSuccess?: () => void
    onDone?: () => void
  }) => Promise<void>
  isLoading: Ref<boolean>
  refetch: (variables?: MissionLifecyclesQueryQueryVariables) => Promise<unknown> | undefined
}

export const MissionLifecycleContextKey = Symbol("MissionLifecycleContext")
export const MissionLifecycleRefetchKey = Symbol("MissionLifecycleRefetch")

export const provideMissionLifecycleContext = ({
  mission,
  refetch,
}: {
  mission: Ref<MissionLifecycleFragmentFragment | null>
  refetch?: (variables?: MissionLifecyclesQueryQueryVariables) => Promise<unknown> | undefined
}): void => {
  provide(MissionLifecycleContextKey, mission)
  provide(MissionLifecycleRefetchKey, refetch)
}

export const useMissionLifecycleContext = (): MissionLifecycleContext => {
  const mission = inject(MissionLifecycleContextKey) as Ref<MissionLifecycleFragmentFragment | null>
  const refetch = inject(MissionLifecycleRefetchKey) as (
    variables?: MissionLifecyclesQueryQueryVariables
  ) => Promise<unknown> | undefined

  const isLoading = ref(false)

  if (!mission.value) {
    throw new Error("MissionLifecycleContext must be provided")
  }

  const updateMissionLifecycle = async ({
    updateInput,
    onSuccess,
    onDone,
  }: {
    updateInput: UpdateMissionLifecycleInput
    onSuccess?: () => void
    onDone?: () => void
  }) => {
    if (!mission.value) {
      return
    }

    isLoading.value = true

    const { mutate } = useMutation()
    const { addSuccess, addError } = useNotification()

    const result = await mutate<
      UpdateMissionLifecycleMutationMutation,
      UpdateMissionLifecycleMutationMutationVariables
    >({
      mutation: UpdateMissionLifecycleMutation,
      variables: {
        missionLifecycleId: mission.value.prn,
        ...updateInput,
      },
      done: () => {
        isLoading.value = false
        onDone?.()
      },
    })

    const error = result?.data?.updateMissionLifecycle.error

    if (error) {
      addError(error.message)
    } else {
      addSuccess("Mission updated successfully")
      onSuccess?.()
    }
  }

  const refetchQueries = [
    {
      query: MissionLifecyclesQuery,
      variables: {
        filter: {
          idIn: [mission.value.id],
        },
        per: 1,
      },
    },
  ]

  return {
    mission: mission.value,
    isLoading,
    refetchQueries,
    refetch,
    updateMissionLifecycle,
  }
}
