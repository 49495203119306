import {
  MissionLifecycleFragmentFragment,
  MissionStaffingPositionDetailFragmentFragment,
} from "@/gql"
import { computed, Ref, inject } from "vue"
import { MissionLifecycleContextKey } from "@/modules/Missions/hooks/useMissionLifecycleContext"

type StaffingPositionContext = {
  canPublishApplication: Ref<boolean>
}

export const useStaffingPositionContext = (
  position?: Ref<MissionStaffingPositionDetailFragmentFragment>
): StaffingPositionContext => {
  const mission = inject(MissionLifecycleContextKey) as Ref<MissionLifecycleFragmentFragment | null>
  if (!mission?.value) {
    throw new Error("MissionLifecycleContext must be provided")
  }
  const canPublishApplication = computed(() => {
    if (!mission.value) {
      return false
    }

    return (
      mission.value.startDate &&
      mission.value.endDate &&
      mission.value.missionPricingProposal?.totalPrice?.amount &&
      mission.value.missionPricingProposal?.contractingEntity &&
      position?.value?.consultantContractPayments?.length
    )
  })

  return {
    canPublishApplication,
  }
}
