import { render, staticRenderFns } from "./AppEditorWindow.vue?vue&type=template&id=79f47cb8&scoped=true&"
import script from "./AppEditorWindow.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AppEditorWindow.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AppEditorWindow.vue?vue&type=style&index=0&id=79f47cb8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79f47cb8",
  null
  
)

export default component.exports