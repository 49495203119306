import { render, staticRenderFns } from "./CreatePaymentStructureForm.vue?vue&type=template&id=798ea0b6&"
import script from "./CreatePaymentStructureForm.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CreatePaymentStructureForm.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports